import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Link } from "gatsby";
import { navigate } from "gatsby-link";

import firebase from "../../../../config/firebase";

import moment from "moment";
import { InformationCircleIcon } from "@heroicons/react/solid";

import UsageChart from "../../../components/usageChart";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../../context/GlobalContextProvider";

import AppLayout from "../../../components/appLayout";

// markup
const Dashboard = () => {
  let state = React.useContext(GlobalStateContext);
  const [stats, setStats] = useState([
    { name: "Max Allowed Threads", stat: 0 },
    { name: "Current Threads", stat: 0 },
    { name: "Upload", stat: 0 },
    { name: "Download", stat: 0 },
    { name: "Total", stat: 0 },
  ]);
  const [loadingData, setLoadingData] = useState(true);
  const [chartData, setChartData] = useState([]);

  // Make sure user is logged in
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setLoadingData(false);
      } else {
        navigate("/login");
      }
    });
  }, []);

  useEffect(() => {
    if (state.user) {
      if (state.user.subscriptions.length <= 0) {
        if (state.user.hasRotatingResidentialTrial) {
          if (moment(state.user.rotatingResidentialTrialEndsAt) <= moment()) {
            navigate("/buy-proxies");
          }
        } else {
          navigate("/buy-proxies");
        }
      }
    }
  }, [state.user]);

  useEffect(() => {
    if (state.userToken) {
      getUsageStats(state.userToken);
      getUsageChartStats(state.userToken);
    }

    let usageInterval = setInterval(() => {
      if (state.userToken) {
        getUsageStats(state.userToken);
      }
    }, 5000);

    return () => clearInterval(usageInterval);
  }, [state.userToken]);

  /**
   * Get usage stat for top cards
   *
   * @param {*} token
   */
  const getUsageStats = (token) => {
    axios
      .get(`${process.env.GATSBY_API_BASE}/usage`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        let updatedStats = stats.map((stat) => {
          if (stat.name == "Max Allowed Threads") {
            return {
              name: stat.name,
              stat: res.data.threads.allowed,
            };
          }
          if (stat.name == "Current Threads") {
            return {
              name: stat.name,
              stat: res.data.threads.current,
            };
          }
          if (stat.name == "Upload") {
            return {
              name: stat.name,
              stat: formatBytes(res.data.upload),
            };
          }
          if (stat.name == "Download") {
            return {
              name: stat.name,
              stat: formatBytes(res.data.download),
            };
          }
          if (stat.name == "Total") {
            return {
              name: stat.name,
              stat: formatBytes(res.data.total),
            };
          }

          return stat;
        });
        setStats(updatedStats);
      })
      .catch((error) => {
        console.log(error.response.status);
      });
  };

  /**
   * Get usage stat for chart
   *
   * @param {*} token
   */
  const getUsageChartStats = (token) => {
    axios
      .get(`${process.env.GATSBY_API_BASE}/usage-chart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        // let finalData = res.data.map((d) => {
        //   return {
        //     upload: formatBytes(d.upload),
        //     download: formatBytes(d.download),
        //     date: d.date,
        //   };
        // });

        setChartData(res.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      });
  };

  /**
   * Format bandwidth
   * https://stackoverflow.com/a/18650828
   *
   * @param {*} bytes
   * @param {*} decimals
   * @returns
   */
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  return (
    <>
      {!loadingData && (
        <AppLayout>
          <Helmet>
            <title>Dashboard</title>
          </Helmet>
          <div className="py-10">
            {state && state.user && state.user.hasRotatingResidentialTrial && (
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-30">
                <div className="rounded-lg border border-blue-400 bg-blue-50 p-3">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        className="h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-sm text-blue-700">
                        You are currently trialing our service, the trial
                        expires on{" "}
                        <strong>
                          {moment(
                            state.user.rotatingResidentialTrialEndsAt
                          ).format("MMM Do, YYYY")}
                        </strong>
                      </p>
                      <p className="text-sm md:mt-0 md:ml-6">
                        <Link
                          to="/buy-proxies"
                          className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                        >
                          Subscribe <span aria-hidden="true">&rarr;</span>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <header>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold leading-tight text-gray-900">
                  Rotating Every Request
                </h1>
              </div>
            </header>
            <main>
              <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div>
                  <dl className="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-5">
                    {stats.map((item, index) => (
                      <div
                        key={index}
                        className="px-3 py-4 overflow-hidden sm:p-5  border border-gray-200 rounded-lg bg-white"
                      >
                        <dt className="text-sm font-medium text-gray-500 truncate">
                          {item.name}
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">
                          {item.stat}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
                {/* Replace with your content */}
                <div className="py-8">
                  <div className="p-8 border border-gray-200 rounded-lg bg-white">
                    <div className="max-w-xl">
                      <div className="mb-4">
                        <label
                          htmlFor="search"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Username
                        </label>
                        <div className="mt-1 relative flex items-center">
                          <input
                            type="text"
                            name="search"
                            id="search"
                            disabled={true}
                            value={state.user?.proxyUsername}
                            className="outline-none p-3 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md bg-gray-100 "
                          />
                          {/*
                            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                            <kbd className="bg-white cursor-pointer inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
                              Copy to clipboard
                            </kbd>
                          </div>
                          */}
                        </div>
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="search"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Password
                        </label>
                        <div className="mt-1 relative flex items-center">
                          <input
                            type="text"
                            name="search"
                            id="search"
                            disabled={true}
                            value={state.user?.proxyPassword}
                            className="outline-none p-3 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md bg-gray-100 "
                          />
                          {/* <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                            <kbd className="bg-white cursor-pointer inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
                              Copy to clipboard
                            </kbd>
                          </div> */}
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="search"
                          className="block text-sm font-medium text-gray-700"
                        >
                          HTTP Proxy Port
                        </label>
                        <div className="mt-1 relative flex items-center">
                          <input
                            type="text"
                            name="search"
                            id="search"
                            disabled={true}
                            value="rotating.residential.proxygrid.io:50000"
                            className="outline-none p-3 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md bg-gray-100 "
                          />
                          {/* <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                            <kbd className="bg-white cursor-pointer inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
                              Copy to clipboard
                            </kbd>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /End replace */}

                {chartData && (
                  <div className="py-8">
                    <div className="p-8 border border-gray-200 rounded-lg bg-white">
                      <div className="">
                        <UsageChart data={chartData} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </main>
          </div>
        </AppLayout>
      )}
    </>
  );
};

export default Dashboard;
