import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  linearGradient,
} from "recharts";

//import styles from "./chart.module.scss";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

// Show date in tooltip title
const TooltipDate = (dateString) => {
  let date = new Date(dateString);

  return (
    monthNames[date.getMonth()] +
    " " +
    getOrdinalNum(date.getDate()) +
    ", " +
    date.getFullYear()
  );
};

/**
 * Format bandwidth
 * https://stackoverflow.com/a/18650828
 *
 * @param {*} bytes
 * @param {*} decimals
 * @returns
 */
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

// https://stackoverflow.com/a/44418732

// Show suffix for date
const getOrdinalNum = (number) => {
  let selector;

  if (number <= 0) {
    selector = 4;
  } else if ((number > 3 && number < 21) || number % 10 > 3) {
    selector = 0;
  } else {
    selector = number % 10;
  }

  return number + ["th", "st", "nd", "rd", ""][selector];
};

// Custom tooltip component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload[0].payload["download"] > 0) {
    return (
      <>
        <div className="chart-tooltip" style={{ position: "relative" }}>
          <div className="tooltipInner" role="tooltip">
            <div className="tooltipTitle" style={{ textAlign: "center" }}>
              {payload &&
                payload[0].payload !== "" &&
                TooltipDate(payload[0].payload["date"])}
            </div>
            <div className="tooltipInnerContent">
              <p>
                <span>Upload</span>
                <span style={{ float: "right", marginLeft: "30px" }}>
                  {payload && payload[0].payload !== ""
                    ? formatBytes(payload[0].payload["upload"])
                    : "0"}
                </span>
              </p>
              <p>
                <span>Download</span>
                <span style={{ float: "right", marginLeft: "30px" }}>
                  {payload && payload[0].payload !== ""
                    ? formatBytes(payload[0].payload["download"])
                    : "0"}
                </span>
              </p>
              <p>
                <span>Total</span>
                <span style={{ float: "right", marginLeft: "30px" }}>
                  {payload && payload[0].payload !== ""
                    ? formatBytes(
                        payload[0].payload["download"] +
                          payload[0].payload["upload"]
                      )
                    : "0"}
                </span>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default class UsageChart extends PureComponent {
  // static jsfiddleUrl = 'https://jsfiddle.net/alidingling/c1rLyqj1/';

  state = {
    data: [],
  };

  componentDidMount() {
    this.setupChart();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setupChart();
    }
  }

  setupChart = () => {
    let data = [];

    // let dateMonthAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

    // Reverse loop so that we can show chart from left to right vs right to left
    // i.e shows most recent on right and most older on the left side
    for (let i = 29; i >= 0; i--) {
      // Get the date for today
      let date = new Date(Date.now() - i * 24 * 60 * 60 * 1000);
      // Get the mapped item
      let f = this.getItem(date);

      // Empty object containing dummy data
      let d = {
        upload: 0,
        download: 0,
        date: date,
      };

      // If we have real data for this date, push that instead of empty object
      if (f) {
        d = f;
      }

      data.push(d);
    }

    this.setState({
      data: data,
    });
  };

  // Map the data with dummy data
  getItem = (date) => {
    if (this.props.data) {
      let newData = this.props.data.find((item) =>
        this.sameDay(new Date(item.date), date)
      );

      return newData;
    }

    return null;
  };

  // Compare 2 dates and return true if both dates are on same day
  sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  render() {
    return (
      <div style={{ width: "100%", height: 300 }}>
        {this.props.data && (
          <ResponsiveContainer>
            <AreaChart
              data={this.state.data}
              margin={{
                top: 5,
                right: 0,
                left: -10,
                bottom: 0,
              }}
              isAnimationActive={true}
            >
              <defs>
                <linearGradient id="download" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#0a84ec" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#0a84ec" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid
                horizontal={true}
                strokeDasharray="5 5"
                stroke="#eee"
              />
              <XAxis
                dataKey={(val) => {
                  if (val.date) {
                    let date = new Date(val.date);

                    return monthNames[date.getMonth()] + " " + date.getDate();
                  }

                  return "";
                }}
                tickLine={{
                  fill: "none",
                  stroke: "#afafaf",
                  display: "none",
                }}
                axisLine={{
                  stroke: "#EAF0F4",
                  strokeOpacity: 0,
                }}
                tick={{
                  fill: "#afafaf",
                  fontSize: 10,
                }}
                interval="preserveEnd"
              />
              <YAxis
                hide={true}
                interval="preserveEnd"
                tickCount={4}
                tickLine={{
                  fill: "none",
                  stroke: "#afafaf",
                  display: "none",
                }}
                axisLine={{
                  stroke: "#EAF0F4",
                  strokeOpacity: 0,
                }}
                tick={{
                  fill: "#afafaf",
                  fontSize: 10,
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                name=""
                dataKey="download"
                stackId="1"
                stroke="#0a84ec"
                fill="#0a84ec"
                fill="url(#download)"
                strokeWidth="2"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    );
  }
}
